import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
import { motion } from 'framer-motion';
import { Parallax } from 'react-scroll-parallax';
import Navbar from '../subComponents/Navbar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Fold from '../components/Fold';
import Hall from '../subComponents/Hall';
import Footer from '../components/Footer';
import { getPartnerFromApi } from '../api/api';

const FrameChild = styled.img`
  width: 395px;
  position: relative;
  height: 703px;
  object-fit: cover;
  z-index: 0;
`;
const FrameItem = styled.div`
  height: 100vh;
  flex: 1 0 0;
  background: url("/rectangle-2@2x.jpg"), lightgray 50% / cover no-repeat;
  background-position: center;
  background-size: cover;
`;
const sLink = styled.div`
  flex: 1;
  position: relative;
`;
const Menu = styled.div`
    width: 854px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: space-evenly;
    gap: 49px;
    flex-wrap: nowrap;
`;

const MenuSection = styled.div`
  margin: 0 !important;
  position: absolute;
  bottom: 0px;
  left: calc(50% - 437px);
  background-color: var(--secondary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xl) var(--padding-3xs);
  z-index: 2;
  text-align: center;
  font-size: var(--paragraph-size);
  color: var(--color-white);
  font-family: var(--paragraph);
`

const RectangleParent = styled.div`
  display: flex;
  height: 100vh;
  min-width: 550px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
`;
const Forello = styled.p`
  margin: 0;
`;
const ForelloExpo = styled.div`
  width: 385px;
  // height: 149.906px;
  text-shadow: 0px 5.151px 51.509px rgba(0, 0, 0, 0.50);
  font-family: Montserrat;
  font-size: 96.58px;
  font-style: normal;
  font-weight: 900;
  line-height: 77.264px; /* 80% */
  letter-spacing: -1.932px;
  background: linear-gradient(180deg, #FFF 12.5%, #D8D8D8 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
`;
const LagenceDesGrands = styled.b`
  align-self: stretch;
  color: var(--Secondary, #423E3B);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const LoremIpsumDolor = styled.div`
  align-self: stretch;
  color: var(--Secondary, #423E3B);
  /* Paragraph */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const HeroDescriptionChild = styled.img`
  width: 184px;
  position: relative;
  height: 23px;
`;
const HeroDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;
const HeroText = styled.div`
  display: flex;
  width: 388px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 53px;
`;
const WelcomeSection = styled.div`
  align-self: stretch;
  background-color: var(--third);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
`;
const FrameInner = styled.div`
  align-self: stretch;
  width: 885px;
  position: relative;
  background-color: var(--third);
  transform: rotate(180deg);
  z-index: 0;
`;
const RectangleIcon = styled.img`
  width: 383px;
  position: absolute;
  margin: 0 !important;
  top: 104px;
  right: 773px;
  border-radius: var(--br-mini);
  height: 395px;
  object-fit: cover;
  z-index: 1;
`;
const Economy = styled.div`
  width: 1203px;
  position: absolute;
  margin: 0 !important;
  top: 539px;
  right: -303px;
  letter-spacing: -0.07em;
  line-height: 222px;
  text-transform: uppercase;
  font-weight: 800;
  display: flex;
  align-items: center;
  height: 232px;
  // z-index: 1;
`;
const RectangleGroup = styled.div`
  align-self: stretch;
  background-color: var(--color-gainsboro-200);
  height: 727px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  z-index: 0;
`;
const FoireInternationaleDe = styled.div`
  width: 427px;
  position: relative;
  line-height: 48px;
  font-weight: 900;
  display: inline-block;
`;
const RectangleDiv = styled.div`
  width: 196px;
  position: relative;
  background-color: #0082ab;
  height: 10px;
`;
const SihRectangleDiv = styled.div`
  width: 196px;
  position: relative;
  background-color: #aa0000;
  height: 10px;
`;
const FrameChild1 = styled.div`
  width: 117px;
  position: relative;
  background-color: #00b8ad;
  height: 10px;
`;
const SihFrameChild1 = styled.div`
  width: 117px;
  position: relative;
  background-color: #ffc500;
  height: 10px;
`;
const RectangleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const SloganDeLa = styled.b`
  margin: 0 !important;
  top: 0px;
  left: 4px;
  font-size: 30px;
  display: inline-block;
  z-index: 0;
`;
const LoremIpsumDolor1 = styled.div`
  width: 327px;
  margin: 0 !important;
  top: 58px;
  left: 4px;
  display: inline-block;
  z-index: 1;
`;
const SloganDeLaFoireInternationParent = styled.div`
  width: 453px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 0px 46px var(--padding-9xs);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-xs);
  font-size: var(--paragraph-size);
  font-family: var(--paragraph);
`;
const FoireInternationaleDeMadagaParent = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 135px;
  right: 216px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-9xs);
  gap: var(--gap-5xl);
  z-index: 1;
  font-size: var(--font-size-31xl);
  color: var(--secondary);
`;
const FairSectionChild = styled.img`
  width: 401px;
  position: absolute;
  margin: 0 !important;
  top: 579px;
  right: 80px;
  border-radius: var(--br-mini);
  height: 265px;
  object-fit: cover;
  z-index: 2;
`;
const Div = styled.div`
  position: relative;
`;
const Wrapper = styled.div`
  width: 38px;
  background-color: var(--secondary);
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-3xl);
  box-sizing: border-box;
`;
const Container = styled.div`
  width: 38px;
  border: 3px solid var(--secondary);
  box-sizing: border-box;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-3xl);
  color: var(--secondary);
`;
const FrameParent = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-21xl);
  gap: var(--gap-11xl);
  z-index: 3;
  font-size: var(--paragraph-size);
  color: var(--color-white);
  font-family: var(--paragraph);
`;
const FairSection = styled.div`
  align-self: stretch;
  background-color: var(--third);
  height: 727px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 3;
  font-size: 200px;
  color: var(--color-gainsboro-200);
`;

const SihSection = styled.div`
  align-self: stretch;
  background-color: var(--third);
  height: 727px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 3;
  font-size: 200px;
  color: var(--color-gainsboro-200);
`;

const FrameChild2 = styled.img`
  width: 388px;
  position: absolute;
  margin: 0 !important;
  top: 136px;
  right: 782px;
  border-radius: var(--br-mini);
  height: 444px;
  object-fit: cover;
  z-index: 0;
`;
const FrameChild3 = styled.img`
  width: 382px;
  position: absolute;
  margin: 0 !important;
  top: 406px;
  right: 1100px;
  border-radius: var(--br-mini);
  height: 469px;
  object-fit: cover;
  z-index: 1;
`;
const SalonInternationalDes = styled.div`
  width: 453px;
  position: relative;
  line-height: 48px;
  font-weight: 900;
  display: inline-block;
`;
const FrameChild4 = styled.div`
  width: 167px;
  position: relative;
  background-color: #f42d2d;
  height: 10px;
`;
const FrameChild5 = styled.div`
  width: 94px;
  position: relative;
  background-color: #f4a42d;
  height: 10px;
`;
const FrameChild6 = styled.div`
  width: 63px;
  position: relative;
  background-color: #22a7f0;
  height: 10px;
`;
const SloganDuSalonInternationalParent = styled.div`
  width: 327px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px var(--padding-9xs);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-xs);
  font-size: var(--paragraph-size);
  font-family: var(--paragraph);
`;
const SalonInternationalDesTranspParent = styled.div`
  width: 453px;
  margin: 0 !important;
  position: absolute;
  top: 156px;
  right: 213px;
  height: 470px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  z-index: 2;
`;
const Business = styled.div`
  width: 717px;
  position: absolute;
  margin: 0 !important;
  top: 198px;
  right: -717px;
  font-size: 100px;
  letter-spacing: -0.07em;
  line-height: 222px;
  text-transform: uppercase;
  font-weight: 800;
  color: var(--color-gainsboro-100);
  display: flex;
  align-items: center;
  height: 232px;
  transform: rotate(90deg);
  transform-origin: 0 0;
  z-index: 3;
`;

const Sitlm = styled.div`
  width: 717px;
  position: absolute;
  margin: 0 !important;
  top: 198px;
  right: -717px;
  font-size: 50px;
  letter-spacing: -0.07em;
  line-height: 50px;
  text-transform: uppercase;
  font-weight: 800;
  color: var(--color-gainsboro-100);
  display: flex;
  align-items: center;
  height: 232px;
  transform: rotate(90deg);
  transform-origin: 0 0;
  z-index: 3;
`;

const FrameDiv = styled.div`
  align-self: stretch;
  background: linear-gradient(290deg, #01111c 0%, #092132 100%);
  height: 777px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  z-index: 0;
`;

const FiaFrameDiv = styled.div`
  align-self: stretch;
  background: linear-gradient(290deg, #01191c 0%, #2f7b18 100%);
  height: 777px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  z-index: 0;
`;

const Frame = styled.div`
  width: 38px;
  background-color: var(--color-gainsboro-100);
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-3xl);
  box-sizing: border-box;
`;
const Wrapper1 = styled.div`
  width: 38px;
  border: 3px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-3xl);
  color: var(--color-gainsboro-100);
`;
const FrameGroup = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-21xl);
  gap: var(--gap-11xl);
  z-index: 1;
  font-size: var(--paragraph-size);
  color: var(--secondary);
  font-family: var(--paragraph);
`;
const ShowSection = styled.div`
  align-self: stretch;
  height: 777px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  font-size: var(--font-size-31xl);
  color: var(--third);
`;

const FiaSection = styled.div`
  align-self: stretch;
  height: 777px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  font-size: var(--font-size-31xl);
  color: var(--third);
`;

const LagenceDesGrands1 = styled.div`
  width: 612px;
  position: relative;
  font-size: var(--font-size-31xl);
  line-height: 48px;
  font-weight: 900;
  font-family: var(--font-montserrat);
  display: inline-block;
`;
const LoremIpsumDolor2 = styled.div`
  width: 701px;
  position: relative;
  display: inline-block;
`;
const LagenceDesGrandsSalonsDeParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-9xs);
  gap: 43px;
`;
const AboutSectionInner = styled.div`
  align-self: stretch;
  height: 682px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-31xl) 0px;
  box-sizing: border-box;
  text-align: center;
  font-family: var(--paragraph);
`;

const SliderSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-31xl) 0px;
  box-sizing: border-box;
  text-align: center;
  font-family: var(--paragraph);
`;

const LandingPageRoot = styled(motion.div)`
  width: 100%;
  position: relative;
  background-color: var(--third);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--paragraph-size);
  color: var(--secondary);
  font-family: var(--font-montserrat);
`;

const PartnersSection = styled.div`
  align-self: stretch;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-55xl);
  gap: var(--gap-xl);
  z-index: 0;
`;

const Partenariat = styled.div`
  position: relative;
  font-size: var(--font-size-31xl);
  line-height: 48px;
  font-weight: 900;
  padding-bottom: 40px;
  color: var(--brand)
`;

const PartenairesParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Partenaires = styled.div`
  width: 400px;
  padding-right: 30px;
  position: relative;
  line-height: 30px;
  font-size: 30px;
  display: inline-block;
  flex-shrink: 0;
`;

const PartenaireLogoParent = styled.div`
  flex: 1;
  // border-bottom: 1px solid var(--color-black);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: var(--padding-11xl);
  gap: var(--gap-10xl);
  min-width: 400px;
`;

const PartenaireInstLogo = styled.img`
  width: auto;
  position: relative;
  height: 100px;
  object-fit: cover;
`;

const SponsorsLogo = styled.img`
  width: auto;
  position: relative;
  height: 90px;
  object-fit: cover;
`;

const PartenaireLogo = styled.img`
  width: auto;
  position: relative;
  height: 70px;
  object-fit: cover;
`;

const Team = styled(motion.img)`
  width: 100vw;
  height: auto;
  object-fit : cover;
`

const Btn = styled(NavLink)`
  position: relative;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 2px black;
  color: black;
  cursor: pointer;

  &:hover {
    background-color: black;
    color: white;
  }
`;

const BtnBlackBg = styled(NavLink)`
  position: relative;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 2px white;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
  }
`;

// Framer-motion config
const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

const fadeShow = {

    hidden: {opacity : 0, transform : "translateY(40px)"},
    show: {
      opacity:1,
      transform : "translateY(0px)",
      transition:{
        staggerChildren: 0.5,
        duration: 0.1,
      }
    }
  
  }

const Main = () => {

  const [menuColor, setMenuColor] = useState('white');
  const [partner, setPartner] = useState([]);

  useEffect(() => {
    getPartnerFromApi().then((data)=>{
        if(data !== undefined){
            setPartner(data)
        }
    })
    const handleScroll = () => {

      const slider = document.getElementById('Slider');
      const welcomeSection = document.getElementById('WelcomeSection');
      const fairSection = document.getElementById('FairSection');
      const sihSection = document.getElementById('SihSection');
      const fiaSection = document.getElementById('FiaSection');
      const showSection = document.getElementById('ShowSection');
      const aboutSectionInner = document.getElementById('AboutSectionInner');
      const partnersSection = document.getElementById('PartnersSection');

      const menuPosition = 30;

      if (isMenuInsideComponent(welcomeSection, menuPosition)) {
        setMenuColor('white');
      } else if (isMenuInsideComponent(fairSection, menuPosition)) {
        setMenuColor('var(--brand)');
      } else if (isMenuInsideComponent(showSection, menuPosition)) {
        setMenuColor('white');
      } else if (isMenuInsideComponent(slider, menuPosition)) {
        setMenuColor('var(--brand)');
      } else if (isMenuInsideComponent(aboutSectionInner, menuPosition)) {
        setMenuColor('var(--brand)');
      } else if (isMenuInsideComponent(partnersSection, menuPosition)) {
        setMenuColor('var(--brand)');
      } else if (isMenuInsideComponent(sihSection, menuPosition)) {
        setMenuColor('var(--brand)');
      } else if (isMenuInsideComponent(fiaSection, menuPosition)) {
        setMenuColor('white');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isMenuInsideComponent = (component, menuPosition) => {
    if (!component) return false;
    const rect = component.getBoundingClientRect();
    return rect.top <= menuPosition && rect.bottom >= menuPosition;
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '450px',
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '400px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1366,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '350px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '250px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '150px',
          slidesToShow: 1
        }
      },
      
    ]
  };

  const [isVisible, setIsVisible] = useState(false);

  // Function to toggle visibility
  const toggleHallVisibility = () => {
    setIsVisible(!isVisible);
  };

    return (
        <div>
            <Navbar menuColor={menuColor} id="Navbar" />
            <Hall imageUrl="images/hall.jpg" isVisible={isVisible} onHide={toggleHallVisibility} />
            <LandingPageRoot
                variants={container}
                initial='hidden'
                animate='show'
                exit={{
                  opacity:0, transition:{duration: 0.5}
                }}
            >
                  {/* <a 
                    href={"./file/fim/infoevent-fim.pdf"} 
                    download="infoevent-fim.pdf"
                    style={{
                      padding: 0
                    }}
                  >
                    <div
                      style={{
                        display : "flex",
                        flexDirection: "column",
                        position : "fixed",
                        bottom: 20,
                        left: 20,
                        padding: 15,
                        borderRadius: 7,
                        background: "white",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                        zIndex: 10
                      }}
                    >
                    <img
                      style={{
                        width: 125,
                        height: "auto",
                        borderRadius: 5
                      }}
                      src='./images/infofim.png'
                    />
                      <div
                        style={{
                          cursor: "pointer",
                          fontSize: 12,
                          background: "#3a38",
                          padding: 5,
                          borderRadius: 5,
                          color: "white"
                        }}
                      >
                        Magazine officiel
                      </div>
                    </div>
                  </a> */}
                <WelcomeSection id="WelcomeSection">
                  <RectangleParent>
                    <HeroText>
                      <Parallax speed={-5}>
                          <motion.div
                              initial={{ filter: "blur(30px)" }}
                              whileInView={{ filter: "blur(0px)", transition: { duration: 1} }}
                          >
                            <ForelloExpo>
                                <Forello>{`Forello Expo`}</Forello>
                            </ForelloExpo>
                            <div
                              style={{
                                width: 404,
                                height: 40,
                                position: "absolute",
                                left: -8,
                                top: 37,
                                background: "var(--brand)",
                                zIndex: -1
                              }}
                            />
                            <div
                              style={{
                                width: 315,
                                height: 40,
                                position: "absolute",
                                left: -8,
                                top: 115,
                                background: "var(--brand)",
                                zIndex: -1
                              }}
                            />
                          </motion.div>
                      </Parallax>
                      <Parallax speed={-5}>
                        <HeroDescription>
                              <LagenceDesGrands>
                                  L’agence des grands salons de Madagascar
                              </LagenceDesGrands>
                              <LoremIpsumDolor>{`Depuis 1995 nous organisons des foires et salons professionnels d’envergure. Durant nos évènements, nous rassemblons acteurs publics et privés, nationaux comme internationaux, autour de secteurs économiques clés de la Grande Ile. Nous occupons par ailleurs le plus grand hall d’exposition de l’Océan Indien.`}</LoremIpsumDolor>
                              {/* <HeroDescriptionChild alt="" src="/rectangle-4.svg" /> */}
                        </HeroDescription>
                      </Parallax>
                  </HeroText>
                  </RectangleParent>
                  <FrameItem />
                  <MenuSection>
                    <Menu>
                        <NavLink to="/galerie">
                          <sLink>Galerie</sLink>
                        </NavLink>
                        <NavLink to="/Timeline">
                          <sLink>Agenda</sLink>
                        </NavLink>
                        {/* <NavLink to="/annuaire/all">
                          <sLink>Annuaire</sLink>
                        </NavLink> */}
                        <NavLink to="/blog">
                          <sLink>Blogs</sLink>
                        </NavLink>
                        {/* <NavLink to={{}}>
                          <sLink
                            onClick={toggleHallVisibility}
                          >
                            Plan de masse
                          </sLink>
                        </NavLink> */}
                    </Menu>
                  </MenuSection>
                </WelcomeSection>
                <ShowSection id="ShowSection">
                    <FrameDiv>
                        {/* <Parallax speed={5}>
                            <FrameChild3 alt="" src="/rectangle-6@2x.jpg" />
                        </Parallax> */}
                        <Parallax speed={-5}>
                            <FrameChild2 alt="" src="/rectangle-5@2x.jpg" />
                        </Parallax>
                        <SalonInternationalDesTranspParent>
                          <motion.div
                              initial={{ opacity: 0, transform : "translateY(20px)" }}
                              whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                          >
                          <FoireInternationaleDe>
                              Salon International des Transports
                          </FoireInternationaleDe>
                          </motion.div>
                          <motion.div
                              initial={{ opacity: 0, transform : "translateY(20px)" }}
                              whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                          >
                              <RectangleContainer>
                              <FrameChild4 />
                              <FrameChild5 />
                              <FrameChild6 />
                              </RectangleContainer>
                          </motion.div>
                            <motion.div
                                initial={{ opacity: 0, transform : "translateY(20px)" }}
                                whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                            >
                                <SloganDeLaFoireInternationParent>
                                  <SloganDeLa>
                                      11 au 14 avril 2024
                                  </SloganDeLa>
                                  <LoremIpsumDolor1>{`Découvrez le Salon International des Transports, de la Logistique et de la Manutention (SITLM), l'événement incontournable pour les acteurs du secteur des transports à Madagascar. Rejoignez-nous pour explorer les dernières innovations, échanger avec les experts et façonner l'avenir du transport dans un cadre dynamique et stimulant.`}</LoremIpsumDolor1>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      gap: 15
                                    }}
                                  >
                                    <BtnBlackBg to={"/salon/SITLM"}>
                                      En savoir plus
                                    </BtnBlackBg>
                                    <BtnBlackBg to="/contact">
                                      Réserver un stand
                                    </BtnBlackBg>
                                  </div>
                                </SloganDeLaFoireInternationParent>
                            </motion.div>
                        </SalonInternationalDesTranspParent>
                        {/* <Business>Business</Business> */}
                        <Sitlm>Logistique <br/>& manutention</Sitlm>
                    </FrameDiv>
                </ShowSection>
                <FairSection id="FairSection">
                    <RectangleGroup>
                    <FrameInner />
                    <Parallax speed={-5}>
                        <RectangleIcon alt="" src="/FIM.jpg" />
                    </Parallax>
                    </RectangleGroup>
                    <FoireInternationaleDeMadagaParent>
                    <motion.div
                        initial={{ opacity: 0, transform : "translateY(20px)" }}
                        whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                    >
                        <SalonInternationalDes>
                        Foire Internationale de Madagascar
                        </SalonInternationalDes>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, transform : "translateY(20px)" }}
                        whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                    >
                        <RectangleContainer>
                            <RectangleDiv />
                            <FrameChild1 />
                        </RectangleContainer>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, transform : "translateY(20px)" }}
                        whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                    >
                        <SloganDeLaFoireInternationParent>
                            <SloganDeLa>
                              23 au 26 mai 2024
                            </SloganDeLa>
                            <LoremIpsumDolor1>{`La Foire Internationale de Madagascar (FIM) est l'événement économique incontournable de la région, offrant une plateforme unique pour les échanges commerciaux et les opportunités d'affaires dans tous les secteurs d'activité. `}</LoremIpsumDolor1>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                gap: 15
                              }}
                            >
                              <Btn to={"/salon/FIM"}>
                                En savoir plus
                              </Btn>
                              <Btn to="/contact">
                                Réserver un stand
                              </Btn>
                            </div>
                        </SloganDeLaFoireInternationParent>
                    </motion.div>
                    </FoireInternationaleDeMadagaParent>
                    {/* <FairSectionChild alt="" src="/rectangle-6@2x.jpg" /> */}
                </FairSection>
                <FiaSection id="FiaSection">
                    <FiaFrameDiv>
                            <Parallax speed={5}>
                                <FrameChild3 alt="" src="/images/corn.jpg" />
                            </Parallax>
                            <Parallax speed={-5}>
                                <FrameChild2 alt="" src="/images/tea.jpg" />
                            </Parallax>
                        <SalonInternationalDesTranspParent>
                            <motion.div
                                initial={{ opacity: 0, transform : "translateY(20px)" }}
                                whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                            >
                                <SalonInternationalDes>
                                Foire Internationale de l'Agriculture
                                </SalonInternationalDes>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, transform : "translateY(20px)" }}
                                whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                            >
                                <RectangleContainer>
                                    <RectangleDiv />
                                    <FrameChild1 />
                                </RectangleContainer>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, transform : "translateY(20px)" }}
                                whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                            >
                                <SloganDeLaFoireInternationParent>
                                  <SloganDeLa>
                                      12 au 15 septembre 2024
                                  </SloganDeLa>
                                  <LoremIpsumDolor1>{`Foire Internationale de l'Agriculture, de l'Elevage et de la Pêche (FIA). La rencontre multisectorielle et inclusive du secteur de l'élevage, de l'agriculture et de la pêche. Une plateforme d'opportunités pour tous les acteurs du domaine. Rencontrez des professionnels, découvrez des innovations et boostez votre activité !`}</LoremIpsumDolor1>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      gap: 15
                                    }}
                                  >
                                    <BtnBlackBg to={"/salon/FIA"}>
                                      En savoir plus
                                    </BtnBlackBg>
                                    <BtnBlackBg to="/contact">
                                      Réserver un stand
                                    </BtnBlackBg>
                                  </div>
                                </SloganDeLaFoireInternationParent>
                            </motion.div>
                        </SalonInternationalDesTranspParent>
                        <Sitlm>élevage & pêche</Sitlm>
                    </FiaFrameDiv>
                </FiaSection>
                <SihSection id="SihSection" style={{overflow: "hidden"}}>
                    <RectangleGroup>
                      <FrameInner />
                      <Economy>Ecoresponsable</Economy>
                      <Parallax speed={5}>
                          <FrameChild3 alt="" src="/images/cement.jpeg" />
                      </Parallax>
                      <Parallax speed={-5}>
                          <FrameChild2 alt="" src="/images/interior.jpg" />
                      </Parallax>
                    </RectangleGroup>
                    <FoireInternationaleDeMadagaParent>
                    <motion.div
                        initial={{ opacity: 0, transform : "translateY(20px)" }}
                        whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                    >
                        <SalonInternationalDes>
                        Salon International de l'Habitat
                        </SalonInternationalDes>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, transform : "translateY(20px)" }}
                        whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                    >
                        <RectangleContainer>
                            <SihRectangleDiv />
                            <SihFrameChild1 />
                        </RectangleContainer>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, transform : "translateY(20px)" }}
                        whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                    >
                        <SloganDeLaFoireInternationParent>
                            <SloganDeLa>
                              24 au 27 octobre 2024
                            </SloganDeLa>
                            <LoremIpsumDolor1>{`L'évènement qui incarne l'innovation et l'engagement du secteur du bâtiment et de l'immobilier face aux besoins d'accompagnement, d'adaptation et de maintenance de l'aménagement d'un logement.`}</LoremIpsumDolor1>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                gap: 15
                              }}
                            >
                              <Btn to={"/salon/SIH"}>
                                En savoir plus
                              </Btn>
                              <Btn to="/contact">
                                Réserver un stand
                              </Btn>
                            </div>
                        </SloganDeLaFoireInternationParent>
                    </motion.div>
                    </FoireInternationaleDeMadagaParent>
                    {/* <FairSectionChild alt="" src="/rectangle-6@2x.jpg" /> */}
                </SihSection>
                <SliderSection id="Slider">
                    <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          paddingLeft: 150,
                          transform: "translateY(40px)"
                        }}
                    >
                        <div
                          style={{
                            fontSize: 30,
                            lineHeight: "48px",
                            fontWeight: 900,
                            fontFamily: "var(--paragraph)",
                            display: "inline-block"
                          }}
                        >
                            Forello Expo
                        </div>
                        <div
                          style={{
                            fontSize: "var(--font-size-31xl)",
                            lineHeight: "48px",
                            fontWeight: 900,
                            fontFamily: "var(--font-montserrat)",
                            display: "inline-block",
                            color: "var(--brand)"
                          }}
                        >
                        L'EXPERIENCE
                        </div>
                        <div
                          style={{
                            fontSize: 20,
                            lineHeight: "48px",
                            fontFamily: "var(--paragraph)",
                            display: "inline-block"
                          }}
                        >
                            au Parc des expositions Forello TANJOMBATO
                        </div>
                    </div>
                  <div style={{ width: '100vw', margin: '0 auto', transform: "rotate(-3deg) scale(1.1)", }}>
                    <Slider {...settings}>
                      <div>
                        <img
                          src="images/image-1.jpg"
                          alt="Image 1"
                          style={{ width: '100%', padding: '75px'}}
                        />
                      </div>
                      <div>
                        <img
                          src="images/image-2.jpg"
                          alt="Image 1"
                          style={{ width: '100%', padding: '75px'}}
                        />
                      </div>
                      <div>
                        <img
                          src="images/image-3.jpg"
                          alt="Image 1"
                          style={{ width: '100%', padding: '75px'}}
                        />
                      </div>
                      <div>
                        <img
                          src="images/image-4.jpg"
                          alt="Image 1"
                          style={{ width: '100%', padding: '75px'}}
                        />
                      </div>
                      <div>
                        <img
                          src="images/image-5.jpg"
                          alt="Image 1"
                          style={{ width: '100%', padding: '75px'}}
                        />
                      </div>
                    </Slider>
                  </div>
                </SliderSection>
                {/* <AboutSection> */}
                <AboutSectionInner id="AboutSectionInner">
                  <LagenceDesGrandsSalonsDeParent>
                  <motion.div
                      initial={{ opacity: 0, transform : "translateY(20px)" }}
                      whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                  >
                      <LagenceDesGrands1>
                      L’agence des grands salons de Madagascar
                      </LagenceDesGrands1>
                  </motion.div>
                  <motion.div
                      initial={{ opacity: 0, transform : "translateY(20px)" }}
                      whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                  >
                      <HeroDescriptionChild alt="" src="/rectangle-4.svg" />
                  </motion.div>
                  <motion.div
                      initial={{ opacity: 0, transform : "translateY(20px)" }}
                      whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                  >
                      <LoremIpsumDolor2>{`L'équipe vous accueille avec enthousiasme et énergie ! Composée de profils diversifiés, notre équipe est jeune, dynamique et hautement compétente. Chacun de nos membres apporte sa propre expertise et ses compétences uniques. Nous sommes passionnés par notre travail et engagés à fournir des solutions exceptionnelles à nos clients. `}</LoremIpsumDolor2>
                  </motion.div>
                    <motion.div
                        initial={{ opacity: 0, transform : "translateY(20px)" }}
                        whileInView={{ opacity: 1, transform : "translateY(0px)", transition: { duration: 1} }}
                    >
                        <Team src="./team.png" />
                    </motion.div>
                  </LagenceDesGrandsSalonsDeParent>
                </AboutSectionInner>
                <Fold/>
                {/* </AboutSection> */}
                <PartnersSection id="PartnersSection">
                  <Partenariat>Partenariat</Partenariat>
                  <PartenairesParent>
                    <Partenaires>{`Sponsors `}</Partenaires>
                    <PartenaireLogoParent>
                      {
                        (partner.sponsor)?(
                          partner.sponsor.map((item, id)=>{
                            return(
                              <NavLink target="_blank" to={{pathname: item.link}} id={id}>
                                  <PartenaireLogo
                                    alt=""
                                    src={item.logo}
                                  />
                              </NavLink>
                            )
                          })
                        ):(
                          <></>
                        )
                      }
                    </PartenaireLogoParent>
                  </PartenairesParent>
                  <PartenairesParent>
                    <Partenaires>{`Partenaires institutionnels `}</Partenaires>
                    <PartenaireLogoParent>
                      {
                        (partner.institutionnel)?(
                          partner.institutionnel.map((item, id)=>{
                            return(
                              <NavLink target="_blank" to={{pathname: item.link}} id={id}>
                                  <PartenaireInstLogo
                                    alt=""
                                    src={item.logo}
                                  />
                              </NavLink>
                            )
                          })
                        ):(
                          <></>
                        )
                      }
                    </PartenaireLogoParent>
                  </PartenairesParent>
                  <PartenairesParent>
                    <Partenaires>{`Partenaires entreprises`}</Partenaires>
                    <PartenaireLogoParent>
                      {
                          (partner.partenaire)?(
                            partner.partenaire.map((item, id)=>{
                              return(
                                <NavLink target="_blank" to={{pathname: item.link}} id={id}>
                                    <PartenaireLogo
                                      alt=""
                                      src={item.logo}
                                    />
                                </NavLink>
                              )
                            })
                          ):(
                            <></>
                          )
                      }
                    </PartenaireLogoParent>
                  </PartenairesParent>
                </PartnersSection>
            </LandingPageRoot>
            <Footer />
        </div>
    )
}

export default Main
