import { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import {OfferMegamenusection, ParticipateMegamenusection} from "./Megamenusection";

const Accueil = styled.div`
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: var(--paragraph-size);
  font-family: var(--paragraph);
  transition: color 0.3s ease;
`;

const MenuItem = styled.div`
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  cursor: pointer;
`;

const Menu = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    position: absolute;
    top: 0px;
    right: 3rem;
    gap: 30px;
    // &>*:not(:last-child){
    //     margin: 0.1rem 0;
    // }
`

const Navbar = (props) => {
    console.log(props.menuColor)
    const [showoffermegamenu, setShowoffermegamenu] = useState(0)
    const [showparticipatemegamenu, setShowparticipatemegamenu] = useState(0)
  return (
    <>
        <div 
            style={{
                width: "100%",
                height: "100px",
                position: "fixed",
                zIndex: 20,
                background: `linear-gradient(0deg, #0000 0%, ${(props.menuColor==="white")?("#00000070"):("#ffffff70")} 100%)`,
                transition: "background ease 0.3s"
            }}
        >
            <Menu>
                <MenuItem>
                    <Accueil style={{color: props.menuColor}}>
                        <NavLink to="/">
                            Accueil
                        </NavLink>
                    </Accueil>
                </MenuItem>
                <MenuItem
                    style={{color: props.menuColor}}
                    onMouseOver={()=>{
                        setShowoffermegamenu(1)
                    }}
                    onMouseLeave={() => {
                        setShowoffermegamenu(0);
                    }}
                >
                    <Accueil>
                        Nos offres
                    </Accueil>
                    {(showoffermegamenu)?(<OfferMegamenusection />):(<></>)}
                </MenuItem>
                <MenuItem
                    style={{color: props.menuColor}}
                    onMouseOver={()=>{
                        setShowparticipatemegamenu(1)
                    }}
                    onMouseLeave={() => {
                        setShowparticipatemegamenu(0);
                    }}
                >
                    <Accueil style={{color: props.menuColor}}>Participer</Accueil>
                    {(showparticipatemegamenu)?(<ParticipateMegamenusection />):(<></>)}
                </MenuItem>
                <MenuItem>
                    <Accueil style={{color: props.menuColor}}>
                        <NavLink to="/contact">
                            Prendre rendez-vous
                        </NavLink>
                    </Accueil>
                </MenuItem>
                <MenuItem>
                    <Accueil style={{color: props.menuColor}}>
                        <NavLink to="/contact">
                            Contact
                        </NavLink>
                    </Accueil>
                </MenuItem>
            </Menu>
        </div>
    </>
  );
};

export default Navbar;